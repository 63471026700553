@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html, body {
    font-family: "Madera";
    color: theme('colors.navy');
    @apply antialiased;
  }

  body.ccib-scroll-lock {
    height: 100vh;
    overflow-y: hidden;
  }

  body::-moz-selection { background: theme('colors.teal'); color: theme('colors.white') }
  body::selection { background: theme('colors.teal'); color: theme('colors.white') }

  p { @apply my-4; }
  hr { @apply border-t border-gray-400; }
  a { @apply text-teal underline; }

}

/* ==================================================== */
/* LAYOUT HELPERS */
/* ==================================================== */

@layer base {
  
  .guard { 
    @apply px-5; 
  }
  @screen md {
    .guard { 
      @apply px-10; 
    }
  }

  @responsive {

    .ccib-h-3x2 {
      height: 0;
      padding-bottom: 175%;
      position: relative;
    }

    .ccib-h-3x4 {
      height: 0;
      padding-bottom: 75%;
      position: relative;
    }

    .ccib-h-1x2 {
      height: 0;
      padding-bottom: 50%;
      position: relative;
    }

    .ccib-h-2x2 {
      height: 0;
      padding-bottom: 100%;
      position: relative;
    }

    .ccib-h-1x3 {
      height: 0;
      padding-bottom: 33.334%;
      position: relative;
    }

    .ccib-h-2x3 {
      height: 0;
      padding-bottom: 66.667%;
      position: relative;
    }

  }

}

/* ==================================================== */
/* WRAPPER */
/* ==================================================== */

@layer base {

  .ccib-container-xl {
    width: 100%;
    margin: 0 auto;
    max-width: calc(1600px + 5rem);
    padding: 0;
  }

  @screen sm {
    .ccib-container-xl {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }
  @screen md {
    .ccib-container-xl {
      padding-left: 2.50rem;
      padding-right: 2.50rem;
    }
  }

  .ccib-container-lg {
    width: 100%;
    max-width: calc(1024px + 5rem);
    margin: 0 auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @screen md {
    .ccib-container-lg {
      padding-left: 2.50rem;
      padding-right: 2.50rem;
    }
  }

}

/* ==================================================== */
/* TYPOGRAPHY */
/* ==================================================== */

@layer base {

  .ccib-h1      { @apply text-5xl leading-tighter tracking-tight font-bold; }
  .ccib-h2      { @apply text-4xl leading-tight font-bold; }
  .ccib-h3      { @apply text-2xl leading-tight font-bold; }
  .ccib-h4      { @apply text-1xl leading-tight font-bold; }
  .ccib-h5      { @apply text-lg leading-tight font-bold; }
  .ccib-lead    { @apply text-1xl leading-snug font-normal; }
  
  .ccib-h4-f      { @apply text-2xl leading-tight font-bold; }

  @screen md {
    .ccib-h1      { @apply text-6xl; }
    .ccib-h2      { @apply text-5xl; }
    .ccib-h3      { @apply text-3xl; }
    .ccib-h4      { @apply text-2xl; }
    .ccib-h5      { @apply text-1xl; }
    .ccib-lead    { @apply text-2xl; }
  }

  .ccib-article-body .ccib-h1 { @apply my-12; }
  .ccib-article-body .ccib-h2 { @apply my-12; }
  .ccib-article-body .ccib-h3 { @apply mt-8; }
  .ccib-article-body hr { @apply my-12; }
  .ccib-article-body ul {
    list-style-type: disc;
    @apply ml-5 my-3;
  }
  .ccib-article-body li {
    @apply pl-3 py-1;
  }

}

  
/* ==================================================== */
/* HIGHLIGHT */
/* ==================================================== */

@layer base {

  div.ccib-highlight {
    @apply my-10 p-10 bg-teal text-white;
  }
  div.ccib-highlight *:first-child {
    @apply mt-0;
  }
  div.ccib-highlight a,
  div.ccib-highlight a:active,
  div.ccib-highlight a:hover {
    @apply text-white font-bold;
  }

}

/* ==================================================== */
/* WYSIWYG IMAGE FIGURES */
/* ==================================================== */

@layer base {

  
  figure.ccib-wysiwyg-image {
    @apply my-10 mx-auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  figure.ccib-wysiwyg-image img {
    display: inline-block;
  }
  
  figure.ccib-wysiwyg-image figcaption {
    @apply text-base text-navy mt-4 opacity-80;
    display: inline-block;
  }

}

/* ==================================================== */
/* MOBILE MENU */
/* ==================================================== */

@layer components {


  #ccib-hamburger-button { 
    @apply px-2 py-3;
    @apply rounded no-underline;
    @apply bg-gray-300 text-navy;
    @apply outline-none appearance-none;
    fill: #001153;
  }

  #ccib-hamburger-button:hover { 
    @apply bg-navy;
    fill: #FFFFFF;
  }

  #ccib-hamburger-button.ccib-hamburger-button--open .ccib-hamburger--open-icon { @apply block; }
  #ccib-hamburger-button.ccib-hamburger-button--close .ccib-hamburger--open-icon { @apply hidden; }
  #ccib-hamburger-button.ccib-hamburger-button--close .ccib-hamburger--close-icon { @apply block; }
  #ccib-hamburger-button.ccib-hamburger-button--open .ccib-hamburger--close-icon { @apply hidden; }

  #ccib-mobile-menu {
    transition: opacity 400ms ease-in-out;
    pointer-events: none;
  }
  
  #ccib-mobile-menu .ccib-mobile-panel {
    transition: 
    transform 450ms ease-in-out,
    opacity 200ms ease-in-out ;
    pointer-events: all;
  }

  .ccib-mobile-menu--open { opacity: 1; }
  .ccib-mobile-menu--closed { opacity: 0; }

  .ccib-mobile-menu--open .ccib-mobile-panel { 
    transition: 
      transform 450ms ease-in-out,
      opacity 200ms ease-in-out;
    transform: translateX(0%); 
    opacity: 1; 
  }
  .ccib-mobile-menu--closed .ccib-mobile-panel { 
    transition: 
      transform 450ms ease-in-out,
      opacity 200ms ease-in-out;
    transform: translateX(100%); 
    opacity: 0; 
  }

}

/* ==================================================== */
/* LOGO */
/* ==================================================== */

@layer components {

  .ccib_logo {
    display: block;
    min-width: 350px;
    position: relative;
  }

  .ccib_logo--img {
    height: 100%;
  }

  .ccib_logo--text { 
    @apply text-navy font-extrabold;
    font-size: 1.375rem;
    line-height: 1.5rem;
    position: absolute;
    width: 100%;
    left: 50px;
    top: 25px;
  }

  .ccib_logo--text-alt { 
    @apply text-navy font-bold;
    font-size: 2rem;
    line-height: 2rem;
    position: absolute;
    width: 100%;
    left: 50px;
    top: 25px;
  }

}

/* ==================================================== */
/* Buttons */
/* ==================================================== */

@layer components {


  .ccib-button{ 
    @apply block px-3 pt-1 pb-2;
    @apply rounded no-underline;
    @apply  bg-gray-300 text-navy font-medium;
  }

  .ccib-button:hover { 
    @apply bg-gray-400;
  }

  .ccib-button.ccib-button--red { @apply bg-red text-white; }
  .ccib-button.ccib-button--red:hover { @apply bg-pink text-white; }

  .ccib-button.ccib-button--teal { @apply bg-teal text-white; }
  .ccib-button.ccib-button--teal:hover { @apply bg-fluo text-white; }

  .ccib-button.ccib-button--navy { @apply bg-navy text-white; }
  .ccib-button.ccib-button--navy:hover { @apply bg-navy text-white; }

  .ccib-button.ccib-button--phone {
    position: relative;
    padding-left: calc(0.75rem + 19px);
  }

  .ccib-button.ccib-button--phone::before { 
    width: 19px; height: 19px;
    left: 0.5rem; top: 0.5rem;
    transform: rotate(10deg);
    position: absolute;
    content: "";
    display: block;
    background-image: url("assets/img/img-phone.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
   }
}

/* ==================================================== */
/* LINKS — NAV */
/* ==================================================== */

@layer components {

  .ccib-nav-link { 
    @apply text-lg no-underline text-navy font-bold;
    @apply block px-4 pt-1 pb-2 mx-0 rounded;
  }

  .ccib-nav-link:hover { 
    @apply bg-gray-200;
  }

  .ccib-mobile-nav-link { 
    @apply bg-gray-200 block px-4 pt-1 pb-2 mx-0 my-5 rounded no-underline text-navy;
  }

  .ccib-mobile-nav-link:hover { 
    @apply bg-gray-300;
  }

  /* --------- */

  .ccib-lang-button { 
    @apply block px-4 pt-1 pb-2 mx-0;
    @apply  bg-gray-300 text-navy rounded font-medium;
  }

  .ccib-lang-button:hover { 
    @apply bg-gray-400;
  }

  .ccib-lang-button img { 
    @apply inline-block pb-1;
  }

  .ccib-lang-button span { 
    @apply inline-block pl-2;
  }
  
  /* --------- */

  .ccib-mobile-lang-button img {
    @apply inline-block pb-1;
  }
  .ccib-mobile-lang-button span {
    @apply inline-block pl-1;
  }

}

/* ==================================================== */
/* LINKS — FOOTER */
/* ==================================================== */

@layer components {

  .ccib-footer-link { 
    @apply block py-2 text-white leading-loose no-underline;
  }

  .ccib-footer-link:hover { 
    @apply text-teal underline;
  }
  .ccib-footer-link img {
    @apply inline-block pb-1;
  }
  .ccib-footer-link span {
    @apply inline-block pl-1;
  }

}

/* ==================================================== */
/* LINKS — CTA */
/* ==================================================== */

@layer components {

  .ccib-cta { 
    @apply relative inline-block;
    @apply my-2 pr-6;
    @apply text-teal text-lg leading-loose no-underline font-medium;
  }

  .ccib-card-cta { 
    @apply text-teal text-lg leading-loose no-underline font-medium;
    @apply mt-2 mb-0;
  }

  @screen md {
    .ccib-cta { 
      @apply text-lg my-4; 
    }
    .ccib-card-cta { 
      @apply mt-2 mb-0;
    }
  }

  .ccib-card-cta::after {
    @apply block absolute inset-0 w-full h-full;
    @apply bg-transparent;
    content: "";
  }
  .ccib-card-cta:hover::after {
    @apply bg-white bg-opacity-7;
  }

  .ccib-card-cta--icon {
    @apply inline relative;
    @apply pr-6;
  }

  .ccib-cta::after,
  .ccib-card-cta--icon::after {
    @apply block absolute;
    content: "";
    top: 0.8rem; right: 0.25rem;
    width: 10px; height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='10px' height='16px' viewBox='0 0 10 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Design' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0.364532059,15.6465679 C0.121509462,15.4109446 0,15.1291564 0,14.8011944 C0,14.473233 0.121509462,14.1914447 0.364532059,13.9558215 L6.51231516,8.00477826 L0.364532059,2.0441767 C0.121509462,1.80855306 0,1.52676578 0,1.19880483 C0,0.870843886 0.121509462,0.589056605 0.364532059,0.35343296 C0.607554811,0.117809315 0.899834494,0 1.24137949,0 C1.582925,0 1.87520417,0.117809315 2.11822682,0.35343296 L10,8.00477826 L2.11822682,15.6465679 C1.8620682,15.882191 1.56978852,16 1.24137949,16 C0.912970462,16 0.620691296,15.882191 0.364532059,15.6465679 Z' id='Fill-1' fill='%2300ACB7'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: transform 200ms ease-in-out;
  }

  .ccib-card-cta--icon::after {
    top: 0.55rem; right: 0.25rem;
  }

  .ccib-cta:hover::after,
  .ccib-card-cta:hover > .ccib-card-cta--icon::after { 
    transform: translateX(0.3rem); 
  }

  .ccib-cta--navy, 
  .ccib-card-cta--navy { @apply text-navy; }
  .ccib-cta--white, 
  .ccib-card-cta--white { @apply text-white; }

  .ccib-cta--navy::after, 
  .ccib-card-cta--navy > .ccib-card-cta--icon::after { 
    background-image: url("data:image/svg+xml,%3Csvg width='10px' height='16px' viewBox='0 0 10 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Design' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0.364532059,15.6465679 C0.121509462,15.4109446 0,15.1291564 0,14.8011944 C0,14.473233 0.121509462,14.1914447 0.364532059,13.9558215 L6.51231516,8.00477826 L0.364532059,2.0441767 C0.121509462,1.80855306 0,1.52676578 0,1.19880483 C0,0.870843886 0.121509462,0.589056605 0.364532059,0.35343296 C0.607554811,0.117809315 0.899834494,0 1.24137949,0 C1.582925,0 1.87520417,0.117809315 2.11822682,0.35343296 L10,8.00477826 L2.11822682,15.6465679 C1.8620682,15.882191 1.56978852,16 1.24137949,16 C0.912970462,16 0.620691296,15.882191 0.364532059,15.6465679 Z' id='Fill-1' fill='%23001153'%3E%3C/path%3E%3C/g%3E%3C/svg%3E"); 
  }
  .ccib-cta--white, 
  .ccib-card-cta--white { @apply text-white; }
  
  .ccib-cta--white::after, 
  .ccib-card-cta--white > .ccib-card-cta--icon::after { 
    background-image: url("data:image/svg+xml,%3Csvg width='10px' height='16px' viewBox='0 0 10 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Design' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0.364532059,15.6465679 C0.121509462,15.4109446 0,15.1291564 0,14.8011944 C0,14.473233 0.121509462,14.1914447 0.364532059,13.9558215 L6.51231516,8.00477826 L0.364532059,2.0441767 C0.121509462,1.80855306 0,1.52676578 0,1.19880483 C0,0.870843886 0.121509462,0.589056605 0.364532059,0.35343296 C0.607554811,0.117809315 0.899834494,0 1.24137949,0 C1.582925,0 1.87520417,0.117809315 2.11822682,0.35343296 L10,8.00477826 L2.11822682,15.6465679 C1.8620682,15.882191 1.56978852,16 1.24137949,16 C0.912970462,16 0.620691296,15.882191 0.364532059,15.6465679 Z' id='Fill-1' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E"); 
  }

}

/* ==================================================== */
/* LINKS — LIST */
/* ==================================================== */

@layer components {

  .ccib-link-list { 
    @apply block relative;
    @apply -mb-px py-4 pl-6 pr-12;
    @apply text-navy text-lg leading-relaxed no-underline;
    @apply border-t border-b border-gray-400;
    @apply transition-colors duration-200 ease-in-out;
    min-width: 100%;
  }

  .ccib-link-list::after {
    @apply block absolute;
    content: "";
    top: 1.5rem; left: 0rem;
    width: 10px; height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='10px' height='16px' viewBox='0 0 10 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Design' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M0.364532059,15.6465679 C0.121509462,15.4109446 0,15.1291564 0,14.8011944 C0,14.473233 0.121509462,14.1914447 0.364532059,13.9558215 L6.51231516,8.00477826 L0.364532059,2.0441767 C0.121509462,1.80855306 0,1.52676578 0,1.19880483 C0,0.870843886 0.121509462,0.589056605 0.364532059,0.35343296 C0.607554811,0.117809315 0.899834494,0 1.24137949,0 C1.582925,0 1.87520417,0.117809315 2.11822682,0.35343296 L10,8.00477826 L2.11822682,15.6465679 C1.8620682,15.882191 1.56978852,16 1.24137949,16 C0.912970462,16 0.620691296,15.882191 0.364532059,15.6465679 Z' id='Fill-1' fill='%2300ACB7'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: transform 200ms ease-in-out;
  }

  .ccib-link-list.prev::after {
    transform: rotate(180deg);
  }

  .ccib-link-list:hover { 
    @apply text-teal;
  }
  .ccib-link-list:hover::after { 
    transform: translateX(0.25rem); 
  }
  .ccib-link-list.prev:hover::after {
    transform: rotate(180deg) translateX(0.25rem); 
  }

}

/* ==================================================== */
/* BACKGROUNDS */
/* ==================================================== */

@layer components {
  .ccib-header-heart-bg {
    @apply relative;
  }
  .ccib-header-heart-bg::after {
    @apply block absolute inset-0 w-full h-full;
    @apply bg-no-repeat;
    @apply opacity-50;
    content: "";
    z-index: -1;
    background-image: url("assets/img/heart_green_2.svg");
    background-position: right -256px top 55%;
    background-size: 1200px;
  }
}

@layer components {
  .ccib-footer-heart-bg {
    @apply relative;
  }
  .ccib-footer-heart-bg::after {
    @apply block absolute inset-0 w-full h-full;
    @apply bg-no-repeat;
    @apply opacity-50;
    content: "";
    background-image: url("assets/img/heart_blue.svg");
    background-position: left -400px bottom 70%;
    background-size: 1500px;
  }
  @screen xl {
    /* .ccib-footer-heart-bg {
      background-position: left -331px top 30%;
      background-size: 1394px;
    } */
  }
}

@layer components {
  .ccib-grid-gradient-bg {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
  @screen xl  {
    .ccib-grid-gradient-bg {
      background-image: var(--ccib-grid-gradient-bg);
    }
  }
}

/* ==================================================== */
/* BIO PIC */
/* ==================================================== */

@layer components {

  .ccib_bio-pic {
    @apply block relative bg-gray-300 h-full flex-1 overflow-hidden;
  }

  .ccib_bio-pic .ccib-bio-pop {
    @apply hidden fixed; 
    @apply w-full h-full inset-0;
    z-index: 999;
    overflow: hidden;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    padding: 5vw;
  }
  
  .ccib_bio--show .ccib-bio-pop {
    @apply block; 
    opacity: 1;
  }

  .ccib-bio-pop--close-btn {
    cursor: pointer;
    transition: transform 300ms ease-in-out;
  }
  .ccib-bio-pop--close-btn:hover {
    transform: scale(1.1);
    transition: transform 300ms ease-in-out;
  }

  .ccib-bio-pop--holder {
    @apply bg-navy bg-opacity-90;
    @apply relative w-full h-full inset-0; 
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll; 
  }
  .ccib-bio-pop--close::after {

  }

  .ccib-bio-pop--text {
    @apply text-white align-middle text-center;
    @apply m-10;
    display: block;
    cursor: pointer;
  }
  
  .ccib_bio-pic--has-bio::before {
    @apply block absolute z-10;
    @apply inset-0 w-full h-full;
    @apply bg-navy opacity-0;
    @apply cursor-pointer;
    @apply transition-opacity duration-300 ease-in-out;
    background-image: url("assets/img/img-loupe.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    content: "";
  }

  .ccib_bio-pic--has-bio:hover::before {
    @apply opacity-75;
  }

  .ccib_bio--show::before  {
    @apply hidden;
  }

  .ccib_bio-pic--hidden {
    @apply hidden;
  }

}